import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { Icon, RangeSlider, RichInput, Size, Space, TextArea } from '@hse-design/react';
import { IconActionPlus } from '@hse-design/react/lib/es/icons';
import { Playground, Props } from 'docz';
import { ComponentLinks } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "richinput"
    }}>{`RichInput`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { RichInput } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks storybook={'/?path=/story/inputs-input--rich-input'} vueStorybook={'/?path=/story/inputs-input--rich-input'} name={'RichInput'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`RichInput — компонент, добавляющий лейбл и сообщение ошибки переданному в проп `}<inlineCode parentName="p">{`component`}</inlineCode>{` компоненту.`}</p>
    <p>{`Передайте лейбл в проп `}<inlineCode parentName="p">{`label`}</inlineCode>{`, сообщение ошибки в `}<inlineCode parentName="p">{`errorMessage`}</inlineCode>{` или подсказку в проп `}<inlineCode parentName="p">{`hint`}</inlineCode>{`. При передаче сообщения ошибки, компоненту `}<inlineCode parentName="p">{`component`}</inlineCode>{` добавляется проп `}<inlineCode parentName="p">{`error`}</inlineCode>{`.`}</p>
    <p>{`Прочие пропы (помимо `}<inlineCode parentName="p">{`label`}</inlineCode>{` и `}<inlineCode parentName="p">{`errorMessage`}</inlineCode>{`, `}<inlineCode parentName="p">{`hint`}</inlineCode>{`) прокидываются на компонент `}<inlineCode parentName="p">{`component`}</inlineCode></p>
    <Playground __position={1} __code={'<>\n  <RichInput\n    label=\"Ваше имя\"\n    placeholder=\"Ваше имя\"\n    hint={\'Введите имя на русском языке\'}\n    left={<Icon icon={IconActionPlus} />}\n  />\n  <Space size={Size.small} vertical />\n  <RichInput\n    component={TextArea}\n    label=\"Label\"\n    placeholder=\"Placeholder\"\n    errorMessage=\"Что-то пошло не так\"\n    autosize\n  />\n</>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Icon,
      RangeSlider,
      RichInput,
      Size,
      Space,
      TextArea,
      IconActionPlus,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <>
    <RichInput label="Ваше имя" placeholder="Ваше имя" hint={'Введите имя на русском языке'} left={<Icon icon={IconActionPlus} mdxType="Icon" />} mdxType="RichInput" />
    <Space size={Size.small} vertical mdxType="Space" />
    <RichInput component={TextArea} label="Label" placeholder="Placeholder" errorMessage="Что-то пошло не так" autosize mdxType="RichInput" />
  </>
    </Playground>
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "произвольный-компонент"
    }}>{`Произвольный компонент`}</h3>
    <p>{`Передайте произвольный компонент в проп `}<inlineCode parentName="p">{`component`}</inlineCode>{`. Прочие пропы (помимо `}<inlineCode parentName="p">{`label`}</inlineCode>{` и `}<inlineCode parentName="p">{`errorMessage`}</inlineCode>{`) прокидываются на компонент `}<inlineCode parentName="p">{`component`}</inlineCode></p>
    <Playground __position={2} __code={'() => {\n  const [state, setState] = useState([\n    { id: \'1\', value: 1 },\n    { id: \'2\', value: 50 },\n  ])\n  return (\n    <div style={{ maxWidth: \'400px\' }}>\n      <RichInput\n        label=\"Label\"\n        placeholder=\"Placeholder\"\n        left={<Icon icon={IconActionPlus} />}\n      />\n      <Space size={Size.small} vertical />\n      <RichInput\n        component={TextArea}\n        label=\"Label\"\n        errorMessage=\"Что-то пошло не так\"\n        placeholder=\"Placeholder\"\n        fullWidth\n        autosize\n      />\n      <Space size={Size.small} vertical />\n      <RichInput\n        component={RangeSlider}\n        label=\"Label\"\n        placeholder=\"Placeholder\"\n        values={state}\n        onChange={setState}\n        fills={\'between\'}\n        min={1}\n        step={1}\n        max={100}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Icon,
      RangeSlider,
      RichInput,
      Size,
      Space,
      TextArea,
      IconActionPlus,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [state, setState] = useState([{
          id: '1',
          value: 1
        }, {
          id: '2',
          value: 50
        }]);
        return <div style={{
          maxWidth: '400px'
        }}>
      <RichInput label="Label" placeholder="Placeholder" left={<Icon icon={IconActionPlus} mdxType="Icon" />} mdxType="RichInput" />
      <Space size={Size.small} vertical mdxType="Space" />
      <RichInput component={TextArea} label="Label" errorMessage="Что-то пошло не так" placeholder="Placeholder" fullWidth autosize mdxType="RichInput" />
      <Space size={Size.small} vertical mdxType="Space" />
      <RichInput component={RangeSlider} label="Label" placeholder="Placeholder" values={state} onChange={setState} fills={'between'} min={1} step={1} max={100} mdxType="RichInput" />
    </div>;
      }}
    </Playground>
    <h3 {...{
      "id": "лейбл"
    }}>{`Лейбл`}</h3>
    <p>{`Передайте лейбл в проп `}<inlineCode parentName="p">{`label`}</inlineCode>{`. В качестве значения вы можете указать название сущности, а в `}<inlineCode parentName="p">{`placeholder`}</inlineCode>{` переданного компонента возможные значения`}</p>
    <Playground __position={3} __code={'() => {\n  const [state, setState] = useState([\n    { id: \'1\', value: 1 },\n    { id: \'2\', value: 50 },\n  ])\n  return (\n    <div style={{ maxWidth: \'400px\' }}>\n      <RichInput\n        label=\"Label\"\n        placeholder=\"Placeholder\"\n        left={<Icon icon={IconActionPlus} />}\n      />\n      <Space size={Size.small} vertical />\n      <RichInput\n        component={TextArea}\n        label=\"Label\"\n        placeholder=\"Placeholder\"\n        fullWidth\n        autosize\n      />\n      <Space size={Size.small} vertical />\n      <RichInput\n        component={RangeSlider}\n        label=\"Label\"\n        placeholder=\"Placeholder\"\n        values={state}\n        onChange={setState}\n        fills={\'between\'}\n        min={1}\n        step={1}\n        max={100}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Icon,
      RangeSlider,
      RichInput,
      Size,
      Space,
      TextArea,
      IconActionPlus,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [state, setState] = useState([{
          id: '1',
          value: 1
        }, {
          id: '2',
          value: 50
        }]);
        return <div style={{
          maxWidth: '400px'
        }}>
      <RichInput label="Label" placeholder="Placeholder" left={<Icon icon={IconActionPlus} mdxType="Icon" />} mdxType="RichInput" />
      <Space size={Size.small} vertical mdxType="Space" />
      <RichInput component={TextArea} label="Label" placeholder="Placeholder" fullWidth autosize mdxType="RichInput" />
      <Space size={Size.small} vertical mdxType="Space" />
      <RichInput component={RangeSlider} label="Label" placeholder="Placeholder" values={state} onChange={setState} fills={'between'} min={1} step={1} max={100} mdxType="RichInput" />
    </div>;
      }}
    </Playground>
    <h3 {...{
      "id": "сообщение-ошибки"
    }}>{`Сообщение ошибки`}</h3>
    <p>{`Передайте сообщение ошибки в `}<inlineCode parentName="p">{`errorMessage`}</inlineCode>{`. При передаче сообщения ошибки, компоненту `}<inlineCode parentName="p">{`component`}</inlineCode>{` добавляется проп `}<inlineCode parentName="p">{`error`}</inlineCode>{`.`}</p>
    <Playground __position={4} __code={'() => {\n  const [state, setState] = useState([\n    { id: \'1\', value: 1 },\n    { id: \'2\', value: 50 },\n  ])\n  return (\n    <div style={{ maxWidth: \'400px\' }}>\n      <RichInput\n        label=\"Label\"\n        errorMessage=\"Что-то пошло не так\"\n        placeholder=\"Placeholder\"\n        left={<Icon icon={IconActionPlus} />}\n      />\n      <Space size={Size.small} vertical />\n      <RichInput\n        component={TextArea}\n        label=\"Label\"\n        placeholder=\"Placeholder\"\n        errorMessage=\"Что-то пошло не так\"\n        fullWidth\n        autosize\n      />\n      <Space size={Size.small} vertical />\n      <RichInput\n        component={RangeSlider}\n        label=\"Label\"\n        errorMessage=\"Что-то пошло не так\"\n        placeholder=\"Placeholder\"\n        values={state}\n        onChange={setState}\n        fills={\'between\'}\n        min={1}\n        step={1}\n        max={100}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Icon,
      RangeSlider,
      RichInput,
      Size,
      Space,
      TextArea,
      IconActionPlus,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [state, setState] = useState([{
          id: '1',
          value: 1
        }, {
          id: '2',
          value: 50
        }]);
        return <div style={{
          maxWidth: '400px'
        }}>
      <RichInput label="Label" errorMessage="Что-то пошло не так" placeholder="Placeholder" left={<Icon icon={IconActionPlus} mdxType="Icon" />} mdxType="RichInput" />
      <Space size={Size.small} vertical mdxType="Space" />
      <RichInput component={TextArea} label="Label" placeholder="Placeholder" errorMessage="Что-то пошло не так" fullWidth autosize mdxType="RichInput" />
      <Space size={Size.small} vertical mdxType="Space" />
      <RichInput component={RangeSlider} label="Label" errorMessage="Что-то пошло не так" placeholder="Placeholder" values={state} onChange={setState} fills={'between'} min={1} step={1} max={100} mdxType="RichInput" />
    </div>;
      }}
    </Playground>
    <h3 {...{
      "id": "подсказка"
    }}>{`Подсказка`}</h3>
    <p>{`Компоненту можно добавить подсказку. Передайте сообщение в проп `}<inlineCode parentName="p">{`hint`}</inlineCode>{`. Если передано сообщение ошибки, то подсказка не будет отображена`}</p>
    <Playground __position={5} __code={'<RichInput\n  label=\"Ваше имя\"\n  hint=\"Введите имя на русском языке\"\n  placeholder=\"Ваше имя\"\n/>'} __scope={{
      props,
      DefaultLayout,
      useState,
      Icon,
      RangeSlider,
      RichInput,
      Size,
      Space,
      TextArea,
      IconActionPlus,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <RichInput label="Ваше имя" hint="Введите имя на русском языке" placeholder="Ваше имя" mdxType="RichInput" />
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={RichInput} mdxType="Props" />
    <p>{`Все остальные пропы пробрасываются на компонент `}<inlineCode parentName="p">{`component`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      